<template>
	<div>
		<div class="qkxx_title">
			{{qktitle}}
			
		</div>
		<div class="num">
			订单编号：{{num}}
			<span style="float: right;" @click="goback()">返回列表</span>
		</div>
		<div>
			<steps :active="active"></steps>
			<trace :active="traceactive" :trace="trace"></trace>
			<articlecont :article="article"></articlecont>
		</div>
		<!--<div class="detailBottom">
			<el-button type="danger">上传附件</el-button>
		</div>-->
	</div>
</template>

<script>
	import steps from '../components/steps'
	import trace from '../components/trace'
	import articlecont from '../components/articlecont'
	import {checksteps,checktrace,checkarticle}from "../../../API/QKD/detail.js"
	export default {
		components: {
			steps,
			trace,
			articlecont,

		},
		data() {
			return {
				qktitle:"",
				num: 123424,
				active:2,
				trace: [],
				article: [],
				present:"",
				 traceactive:""
			}
		},mounted(){
			let id=this.$route.query.wid
			if(this.$route.query.id==8){
				this.qktitle="待结算稿件"
			}else if(this.$route.query.id==9){
				this.qktitle="已结算稿件"
			}
			checksteps(this.$route.query.wid).then(res=>{
				this.num=res.data.data.contributionnumber
				if(res.data.data.state==0||res.data.data.state==1||res.data.data.state==71||res.data.data.state==72||res.data.data.state==81||res.data.data.state==82){
														this.active=2
													}else if(res.data.data.state==21||res.data.data.state==22){
														this.active=2
													}else if(res.data.data.state==30||res.data.data.state==31||res.data.data.state==5||res.data.data.state==6){
														this.active=2
													}else if(res.data.data.state==32){
														this.active=4
													}else{
														this.active=4
													}
			})
			checktrace(this.$route.query.wid).then(res=>{
				
				this.trace=res.data.data
				this.traceactive=this.trace.length-1
			})
			checkarticle(this.$route.query.wid).then(res=>{
				console.log(res)
				this.article=res.data.data
			})
		},methods:{
			goback(){
				history.back()
			}
		}
	}
</script>

<style>
	.num {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
		font-size: 14px;
		line-height: 30px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		padding: 0 20px;
	}
	.num span{
		cursor: pointer;
	}
	.detailBottom{
		padding: 20px;
		display: flex;
		justify-content: flex-end;
	}
</style>